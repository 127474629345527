import { Storage } from '@angular/fire/storage';

export type AnimalStorageSubFolders = 'contracts' | 'medicalResults' | 'otherFiles';
export const supportedFileExtension = [
  '.jpg',
  '.jpeg',
  '.png',
  '.docx',
  '.doc',
  '.pdf'
];

export type FileSuffix = 'head' | 'body' | 'contract' | 'medicalResult' | 'other';

export const fileSuffixes: FileSuffix[] = [
  'head',
  'body',
  'contract',
  'medicalResult',
  'other'
];

export interface UploadArgs {
  suffix: FileSuffix;
  storage: Storage;
  rescueOrgFolder: string;
  animalFolder: string;
  file: File;
  subFolder?: string;
}

export interface SuccessUploadResult {
  name: string;
  url: string;
  size: number;
  type: string;
  path: string
}
