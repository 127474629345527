import { Injectable } from '@angular/core';
import { fileSuffixes, SuccessUploadResult, supportedFileExtension, UploadArgs } from './models';
import { getDownloadURL, ref, StorageReference, uploadBytes } from '@angular/fire/storage';

@Injectable({ providedIn: 'root' })
export class StorageService {
  async upload(args: UploadArgs): Promise<any> {
    const {
      file,
      storage,
      suffix,
      rescueOrgFolder,
      animalFolder,
      subFolder
    } = args;

    if (file) {
      if (!this.isValidExtension(file)) throw new Error(`A következő fájl kiterjesztése nem támogatott: ${ file.name }`);
      const fileNameWithSuffix = `${ this.getName(this.removeSuffixesFromFileName(file.name)) }_${ suffix }${ this.getExtension(file) }`;
      const fileWithSuffix = new File([file], `${ fileNameWithSuffix }`, {type: file.type});
      let fileRef: StorageReference;
      if (subFolder) {
        fileRef = ref(storage, `${ rescueOrgFolder }/${ animalFolder }/${ subFolder }/${ fileWithSuffix.name }`);
      } else {
        fileRef = ref(storage, `${ rescueOrgFolder }/${ animalFolder }/${ fileWithSuffix.name }`);
      }

      const url = await uploadBytes(fileRef, fileWithSuffix).then(async () => await getDownloadURL(fileRef));

      return {
        name: fileWithSuffix.name,
        url,
        size: fileWithSuffix.size,
        type: fileWithSuffix.type,
        path: fileRef.fullPath
      };
    }
  }

  removeSuffixesFromFileName(fileName: string) {
    if (!fileSuffixes.some((intFileAtt) => fileName.includes(intFileAtt))) {
      return fileName;
    }
    const replaceRegex = new RegExp(`${fileSuffixes.join('|')}`, 'gi');
    return fileName.replaceAll(replaceRegex, '');
  }
  isValidExtension(file: File) {
    return supportedFileExtension.some((ext) => this.getExtension(file) === ext);
  }
  getName(name: string) {
    return name.slice(0, name.lastIndexOf('.'))
  }
  getExtension(file: File | SuccessUploadResult) {
    return file.name.slice(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
  }
}
