import { inject, Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  query,
  where,
  doc,
  Timestamp,
  runTransaction,
  onSnapshot,
  getDoc,
  writeBatch,
  orderBy,
  increment,
  getDocs
} from '@angular/fire/firestore';
import { UserQuery } from '../user/user.query';
import {
  AnimalFilter, AnimalFiltersGroup, AnimalStatus,
  FireBaseAnimalWithId,
  FirebaseMedical
} from '../../pages/animals/models';
import { catchError, Observable } from 'rxjs';
import { deleteObject, getDownloadURL, listAll, ref, Storage } from '@angular/fire/storage';
import { RescueOrgQuery } from '../rescue-org/rescue-org.query';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseAnimal } from '../../pages/animals/models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { StorageService } from '../../services/storage/storage.service';
import { SuccessUploadResult } from '../../services/storage/models';
import { Auth } from "@angular/fire/auth";
import {
  animalCollectionKey,
  animalFilesCollectionKey,
  medicalCollectionKey,
  rescueOrgCollectionKey
} from "../../globalVariables";

@Injectable({ providedIn: 'root' })
export class AnimalsService {
  private firestore: Firestore = inject(Firestore);
  private auth: Auth = inject(Auth);
  private fireStorage: Storage = inject(Storage);
  private userQuery = inject(UserQuery);
  private rescueOrgQuery = inject(RescueOrgQuery);
  private fb = inject(FormBuilder);
  private storageService = inject(StorageService);

  filters: AnimalFiltersGroup[] = [
    {
      label: 'Ivarosság',
      items: [
        { displayValue: 'ivaros', fieldName: 'gender', value: 'ivaros', disabled: false },
        { displayValue: 'ivartalan', fieldName: 'gender', value: 'ivartalan', disabled: false },
      ]
    },
    {
      label: 'Szívférgesség',
      items: [
        { displayValue: 'pozitív', fieldName: 'heartwormStatus', value: true, disabled: false },
        { displayValue: 'negatív', fieldName: 'heartwormStatus', value: false, disabled: false },
      ]
    },
    {
      label: 'Vércsoport',
      items: [
        { displayValue: '+', fieldName: 'bloodType', value: '+', disabled: false },
        { displayValue: '-', fieldName: 'bloodType', value: '-', disabled: false },
        { displayValue: 'nincs adat', fieldName: 'bloodType', value: null, disabled: false },
      ]
    }
  ]
  searchFilter = '';
  selectedFilters: AnimalFilter[] = [];
  firstAnimal = 0;
  rowsPerPage: any[] = [10, 20, 50, { showAll: 'Összes' }];
  rows = this.rowsPerPage[0];
  maxDate = new Date();
  private readonly animalRef = collection(this.firestore, animalCollectionKey);
  private medicalRef = collection(this.firestore, medicalCollectionKey);
  animalCreationForm: FormGroup = this.fb.group({
    baseData: this.fb.group({
      number: [''],
      name: ['', [ Validators.required ]],
      sex: [null, [ Validators.required ]],
      gender: ['', [ Validators.required ]],
      chipNumber: [''],
      breed: ['', [ Validators.required ]],
      bornDate: ['', [ Validators.required ]],
      registrationDate: [new Date()],
      color: ['', [ Validators.required ]],
      registrationCircumstances: [''],
    }),
    medicalData: this.fb.group({
      info: [''],
      vermifuge: ['', [ Validators.required ]],
      vaccination: ['', [ Validators.required ]],
      heartwormStatus: [false, [ Validators.required ]],
      underTreatment: [false, [ Validators.required ]],
      bloodType: [null],
      lastTreatment: [{ value: '', disabled: true }],
    }),
    socialData: this.fb.group({
      info: [''],
      facePicture: [null],
      bodyPicture: [null],
    }),
    additionalFiles: this.fb.group({
      contracts: [[], { nonNullable: true }],
      medicalResults: [[], { nonNullable: true }],
      otherFiles: [[], { nonNullable: true }],
    })
  });

  constructMultiSelectFilters() {
    return this.selectedFilters.map((filter) => {
      return (entity: FireBaseAnimalWithId) => entity[filter.fieldName] === filter.value;
    });
  }
  /* Form */
  get firebaseAnimalData(): FirebaseAnimal {
    return {
      bodyPictureURL: '',
      bornDate: this.convertDateToTimestamp(this.baseDataGroup.get('bornDate')?.value) as Timestamp,
      registrationDate: this.convertDateToTimestamp(this.baseDataGroup.get('registrationDate')?.value) as Timestamp,
      registrationCircumstances: this.baseDataGroup.get('registrationCircumstances')?.value ? this.baseDataGroup.get('registrationCircumstances')?.value : '',
      breed: this.baseDataGroup.get('breed')?.value,
      chipNumber: this.baseDataGroup.get('chipNumber')?.value ? this.baseDataGroup.get('chipNumber')?.value : '',
      number: this.baseDataGroup.get('number')?.value ? this.baseDataGroup.get('number')?.value : '',
      color: this.baseDataGroup.get('color')?.value,
      facePictureURL: '',
      gender: this.baseDataGroup.get('gender')?.value,
      heartwormStatus: !!this.medicalDataGroup.get('heartwormStatus')?.value,
      name: this.baseDataGroup.get('name')?.value,
      rescueOrgId: this.userQuery.selectedRescueOrgId,
      sex: this.baseDataGroup.get('sex')?.value,
      bloodType: this.medicalDataGroup.get('bloodType')?.value,
      info: this.socialDataGroup.get('info')?.value,
      status: '',
    };
  }
  get firebaseMedicalData(): FirebaseMedical {
    return {
      gender: this.baseDataGroup.get('gender')?.value,
      heartwormStatus: this.medicalDataGroup.get('heartwormStatus')?.value,
      name: this.baseDataGroup.get('name')?.value,
      underTreatment: this.medicalDataGroup.get('underTreatment')?.value,
      info: this.medicalDataGroup.get('info')?.value,
      lastTreatment: this.convertDateToTimestamp(this.medicalDataGroup.get('lastTreatment')?.value),
      bloodType: this.medicalDataGroup.get('bloodType')?.value,
      vaccination: this.toTimestampArray(this.medicalDataGroup.get('vaccination')?.value),
      vermifuge: this.toTimestampArray(this.medicalDataGroup.get('vermifuge')?.value),
      rescueOrgId: this.userQuery.selectedRescueOrgId
    }
  }
  get baseDataGroup(): FormGroup {
    return this.animalCreationForm.get('baseData') as FormGroup;
  }
  get socialDataGroup(): FormGroup {
    return this.animalCreationForm.get('socialData') as FormGroup;
  }
  get medicalDataGroup(): FormGroup {
    return this.animalCreationForm.get('medicalData') as FormGroup;
  }
  get additionalFilesGroup(): FormGroup {
    return this.animalCreationForm.get('additionalFiles') as FormGroup;
  }
  toDateArray(timestamps: Timestamp[]): Date[] | null {
    if (timestamps.length === 0) return null;
    return timestamps.map((timestamp) => timestamp.toDate())
  }
  /* CRUD */
  /* Create */
  createAnimal() {
    return fromPromise(new Promise(async (resolve, reject) => {
      const newAnimal: FirebaseAnimal = this.firebaseAnimalData;
      const newMedical: FirebaseMedical = this.firebaseMedicalData;
      const animalDocRef = doc(this.animalRef);
      const medicalRef = doc(this.medicalRef, animalDocRef.id);
      const filesRef = collection(animalDocRef, animalFilesCollectionKey);
      const rescueOrgRef = doc(this.firestore, rescueOrgCollectionKey, this.userQuery.selectedRescueOrgId);
      const batch = writeBatch(this.firestore);

      await Promise.all([
        this.storageService.upload({
          animalFolder: animalDocRef.id,
          rescueOrgFolder: this.userQuery.selectedRescueOrgId,
          storage: this.fireStorage,
          suffix: 'head',
          file: this.socialDataGroup.get('facePicture')?.value
        }).then((res: SuccessUploadResult) => {
          newAnimal.facePictureURL = res.url;
        }),
        this.storageService.upload({
          animalFolder: animalDocRef.id,
          rescueOrgFolder: this.userQuery.selectedRescueOrgId,
          storage: this.fireStorage,
          suffix: 'body',
          file: this.socialDataGroup.get('bodyPicture')?.value
        }).then((res: SuccessUploadResult) => {
          newAnimal.bodyPictureURL = res.url;
        })
      ]);

      for (const file of (this.additionalFilesGroup.get('contracts')?.value as File[])) {
        await this.storageService.upload({
          animalFolder: animalDocRef.id,
          rescueOrgFolder: this.userQuery.selectedRescueOrgId,
          storage: this.fireStorage,
          suffix: 'contract',
          file,
          subFolder: 'contracts'
        }).then((res: SuccessUploadResult) => {
          const newFileDoc = doc(filesRef);

          batch.set(newFileDoc, res);
        });
      }

      for (const file of (this.additionalFilesGroup.get('medicalResults')?.value as File[])) {
        await this.storageService.upload({
          animalFolder: animalDocRef.id,
          rescueOrgFolder: this.userQuery.selectedRescueOrgId,
          storage: this.fireStorage,
          suffix: 'medicalResult',
          file,
          subFolder: 'medicalResults'
        }).then((res: SuccessUploadResult) => {
          const newFileDoc = doc(filesRef);

          batch.set(newFileDoc, res);
        });
      }

      for (const file of (this.additionalFilesGroup.get('otherFiles')?.value as File[])) {
        await this.storageService.upload({
          animalFolder: animalDocRef.id,
          rescueOrgFolder: this.userQuery.selectedRescueOrgId,
          storage: this.fireStorage,
          suffix: 'other',
          file,
          subFolder: 'otherFiles'
        }).then((res: SuccessUploadResult) => {
          const newFileDoc = doc(filesRef);

          batch.set(newFileDoc, res);
        });
      }
      newAnimal.status = 'ACTIVE';

      batch.set(animalDocRef, newAnimal);
      batch.set(medicalRef, newMedical);
      batch.set(rescueOrgRef, {
        totalAnimals: increment(1),
        totalActiveAnimals: increment(1)
      }, { merge: true });

      await batch.commit();
      resolve(newAnimal.name);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }
  /* Reads */
  readAnimals(orgId: string) {
    const queryByRescueOrg = query(
      this.animalRef,
      where('rescueOrgId', '==', orgId),
      where('status', 'not-in', ['DELETED', 'ADOPTED']),
      orderBy('status', 'asc'),
      orderBy('heartwormStatus', 'desc'),
      orderBy('gender'),
      orderBy('name', 'asc'),
    );
    return new Observable<FireBaseAnimalWithId[]>((observer) => {
      const unsubscribe = onSnapshot(queryByRescueOrg, (snapshot) => {
        const data: any[] = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        observer.next(data);
      }, (err) => {
        console.log(err);
      });

      return () => unsubscribe();
    });
  }
  readAnimal(documentId: string) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const animalDocRef = doc(this.animalRef, documentId);
      const animalFileCollectionRef = collection(doc(this.animalRef, documentId), animalFilesCollectionKey);
      const animalFilesSnap = await getDocs(animalFileCollectionRef);
      const medicalDocRef = doc(this.medicalRef, documentId);
      const medicalSnap = await getDoc(medicalDocRef);
      const allFile = animalFilesSnap.docs.map((doc) => doc.data());

      if (!animalDocRef || !medicalSnap.exists()) reject('Document ref not exists!');

      const contracts = allFile.filter((file: any) => file.name.includes('contract'));
      const medicalResults = allFile.filter((file: any) => file.name.includes('medicalResult'));
      const otherFiles = allFile.filter((file: any) => file.name.includes('other'));

      resolve({
        medicalData: medicalSnap.data(),
        filesData: {
          contracts,
          medicalResults,
          otherFiles
        }
      });
    })).pipe(catchError((err) => {
      throw new Error(err);
    }))
  }
  /* Update */
  updateAnimal(documentId: string) {
    // Todo: update the picture
    // Todo: Upload new files
    return fromPromise(new Promise(async (resolve, reject) => {
      const animalDocRef = doc(this.animalRef, documentId);
      const medicalDocRef = doc(this.medicalRef, documentId);
      const filesRef = collection(animalDocRef, animalFilesCollectionKey);
      const batch = writeBatch(this.firestore);

      if (!animalDocRef) reject('Animal document does not exists!');
      if (!medicalDocRef) reject('Medical document does not exists!');

      if (this.baseDataGroup.dirty || this.medicalDataGroup.dirty || this.socialDataGroup.dirty || this.additionalFilesGroup.dirty) {
        const changedValuesOfBaseData = this.baseDataGroup.value;
        const changedValuesOfMedicalData = this.medicalDataGroup.value
        const changedValuesOfSocialData = this.socialDataGroup.value
        const newContracts = (this.additionalFilesGroup.get('contracts')?.value as any[]).filter((file) => !file.url);
        const newMedicalResults = (this.additionalFilesGroup.get('medicalResults')?.value as any[]).filter((file) => !file.url);
        const newOtherFiles = (this.additionalFilesGroup.get('otherFiles')?.value as any[]).filter((file) => !file.url);

        const medicalKeysInAnimal = ['heartwormStatus', 'bloodType', 'sex'];
        const socialKeysInAnimal = ['info', 'facePicture', 'bodyPicture'];
        const animalChanges = {...changedValuesOfBaseData};
        Object.keys(changedValuesOfMedicalData).some((medicalKey) => {
          if (medicalKeysInAnimal.includes(medicalKey)) {
            animalChanges[medicalKey] = changedValuesOfMedicalData[medicalKey];
          }
        });
        Object.keys(changedValuesOfSocialData).some((socialKey) => {
          if (socialKeysInAnimal.includes(socialKey)) {
            if (socialKey.includes('Picture')) {
              animalChanges[`${socialKey}URL`] = changedValuesOfSocialData[socialKey];
            } else {
              animalChanges[socialKey] = changedValuesOfSocialData[socialKey];
            }
          }
        });

        const animalKeysInMedical = ['name', 'gender'];
        const medicalChanges = {
          ...changedValuesOfMedicalData,
        };
        if (changedValuesOfMedicalData.vermifuge) {
          medicalChanges.vermifuge = changedValuesOfMedicalData.vermifuge?.map((date: { date: string }) => date.date);
        }
        if (changedValuesOfMedicalData.vaccination) {
          medicalChanges.vaccination = changedValuesOfMedicalData.vaccination?.map((date: { date: string }) => date.date);
        }
        Object.keys(changedValuesOfBaseData).some((animalKey) => {
          if (animalKeysInMedical.includes(animalKey)) {
            medicalChanges[animalKey] = changedValuesOfBaseData[animalKey];
          }
        });

        if (Object.values(animalChanges).some((value) => typeof value === 'undefined')
          || Object.values(medicalChanges).some((value) => typeof value === 'undefined'))
          reject('One or more of the values are undefined which is rejected by Firebase!')

        for (const file of newContracts) {
          await this.storageService.upload({
            animalFolder: animalDocRef.id,
            rescueOrgFolder: this.userQuery.selectedRescueOrgId,
            storage: this.fireStorage,
            suffix: 'contract',
            file,
            subFolder: 'contracts'
          }).then((res: SuccessUploadResult) => {
            const newFileDoc = doc(filesRef);

            batch.set(newFileDoc, res);
          });
        }

        for (const file of newMedicalResults) {
          await this.storageService.upload({
            animalFolder: animalDocRef.id,
            rescueOrgFolder: this.userQuery.selectedRescueOrgId,
            storage: this.fireStorage,
            suffix: 'medicalResult',
            file,
            subFolder: 'medicalResults'
          }).then((res: SuccessUploadResult) => {
            const newFileDoc = doc(filesRef);

            batch.set(newFileDoc, res);
          });
        }

        for (const file of newOtherFiles) {
          await this.storageService.upload({
            animalFolder: animalDocRef.id,
            rescueOrgFolder: this.userQuery.selectedRescueOrgId,
            storage: this.fireStorage,
            suffix: 'other',
            file,
            subFolder: 'otherFiles'
          }).then((res: SuccessUploadResult) => {
            const newFileDoc = doc(filesRef);

            batch.set(newFileDoc, res);
          });
        }

        batch.set(animalDocRef, animalChanges, { merge: true });
        batch.set(medicalDocRef, medicalChanges,{ merge: true });
      }

      await batch.commit();
      resolve(this.baseDataGroup.get('name')?.value);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }
  deceaseAnimal(documentId: string, dateOfDeath: Date) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const animalDocRef = doc(this.animalRef, documentId);
      // const medicalDocRef = doc(this.medicalRef, documentId);
      const batch = writeBatch(this.firestore);

      if (!animalDocRef) reject('Animal document does not exists!');
      // if (!medicalDocRef) reject('Medical document does not exists!');

      batch.set(animalDocRef, {
        status: 'DECEASED',
        dateOfDeath: this.convertDateToTimestamp(dateOfDeath),
      }, { merge: true });

      await batch.commit();
      resolve(this.baseDataGroup.get('name')?.value);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }

  /* Delete */
  deleteAnimal(documentId: string) {
    return this.setAnimalStatus(documentId, 'DELETED');
  }
  purgeAnimal(documentId: string) {
    return fromPromise(
      runTransaction(this.firestore, async (transaction) => {
        const animalDocRef = doc(this.animalRef, documentId);
        const medicalDocRef = doc(this.medicalRef, documentId);
        const animalFileCollectionRef = collection(doc(this.animalRef, documentId), animalFilesCollectionKey);
        const animalFilesSnap = await getDocs(animalFileCollectionRef);
        const allFile = animalFilesSnap.docs.map((doc) => doc.ref);

        const storageFolderRef = ref(this.fireStorage, `${this.userQuery.selectedRescueOrgId}/${documentId}`);
        const storageContractsFolderRef = ref(this.fireStorage, `${this.userQuery.selectedRescueOrgId}/${documentId}/contracts`);
        const storageMedicalResultsFolderRef = ref(this.fireStorage, `${this.userQuery.selectedRescueOrgId}/${documentId}/medicalResults`);
        const storageOtherFilesFolderRef = ref(this.fireStorage, `${this.userQuery.selectedRescueOrgId}/${documentId}/otherFiles`);
        const [facePictureRef, bodyPictureRef] = await listAll(storageFolderRef).then((res) => res.items);
        const contractRefs = await listAll(storageContractsFolderRef).then((res) => res.items);
        const medicalResultsRefs = await listAll(storageMedicalResultsFolderRef).then((res) => res.items);
        const otherFileRefs = await listAll(storageOtherFilesFolderRef).then((res) => res.items);

        const storageDeletionTasks: Promise<any>[] = [
          deleteObject(facePictureRef),
          deleteObject(bodyPictureRef),
        ];

        contractRefs.forEach((contractRef) => storageDeletionTasks.push(deleteObject(contractRef)));
        medicalResultsRefs.forEach((medicalResultsRef) => storageDeletionTasks.push(deleteObject(medicalResultsRef)));
        otherFileRefs.forEach((otherFileRef) => storageDeletionTasks.push(deleteObject(otherFileRef)));

        await Promise.all(storageDeletionTasks);

        for (const fileDoc of allFile) {
          transaction.delete(fileDoc);
        }

        transaction.delete(animalDocRef);
        transaction.delete(medicalDocRef);

        const rescueOrgRef = doc(this.firestore, rescueOrgCollectionKey, this.userQuery.selectedRescueOrgId);
        transaction.set(rescueOrgRef, {
          totalAnimals: increment(-1)
        }, { merge: true });
      })
    ).pipe(catchError((err) => {
      throw new Error(err);
    }))
  }
  /* Utils */
  setAnimalStatus(documentId: string, status: AnimalStatus) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const animalDocRef = doc(this.animalRef, documentId);
      // const medicalDocRef = doc(this.medicalRef, documentId);
      const batch = writeBatch(this.firestore);

      if (!animalDocRef) reject('Animal document does not exists!');
      // if (!medicalDocRef) reject('Medical document does not exists!');

      batch.set(animalDocRef, {
        status: status
      }, { merge: true });
      if (['DELETED', 'ADOPTED'].includes(status)) {
        const rescueOrgRef = doc(this.firestore, rescueOrgCollectionKey, this.userQuery.selectedRescueOrgId);

        batch.set(rescueOrgRef, {
          totalActiveAnimals: increment(-1)
        }, { merge: true });
      }

      await batch.commit();
      resolve(this.baseDataGroup.get('name')?.value);
    })).pipe(catchError((err) => {
      throw new Error(err);
    }));
  }
  private convertDateToTimestamp(date: Date): Timestamp | null {
    if (!date) return null;

    return Timestamp.fromDate(date);
  }
  private toTimestampArray(dates: {date: Date}[]): Timestamp[] | null {
    if (!dates) return null;
    if (dates.length === 0) return null;

    return dates.map((object) => Timestamp.fromDate(object.date));
  }
}
